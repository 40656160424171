import environment from './environment.ts'

const config = {
  aws_project_region: environment.awsRegion,
  aws_cognito_region: environment.awsRegion,
  aws_user_pools_id: environment.cognitoUserPoolId,
  aws_user_pools_web_client_id: environment.oidcClientId,
  oauth: {},
  Auth: {
    // Amazon Cognito Region
    region: environment.awsRegion,
    // Cognito User Pool ID
    userPoolId: environment.cognitoUserPoolId,
    // Amazon Cognito Web Client ID
    userPoolWebClientId: environment.oidcClientId,
  },
  authenticationFlowType: 'CUSTOM_AUTH',
}

export default config
