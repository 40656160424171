import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { tw, twx } from '@shared/utils/tailwind'
import SidebarContext from './SidebarContext'

export default function Sidebar({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) {
  const location = useLocation()
  const [isAnyMenuOpen, setIsAnyMenuOpen] = useState(false)
  const [showMobileSidebar, setShowMobileSidebar] = useState(false)

  useEffect(() => {
    setShowMobileSidebar(false)
  }, [location])

  return (
    <SidebarContext.Provider
      value={{
        isAnyMenuOpen,
        setIsAnyMenuOpen,
        showMobileSidebar,
        setShowMobileSidebar,
      }}
    >
      {isAnyMenuOpen && (
        <div
          className={tw`absolute bottom-0 left-0 right-0 top-0 z-[3] h-screen w-screen bg-transparent`}
          onClick={() => setIsAnyMenuOpen(false)}
        />
      )}
      <nav
        aria-label="Main Navigation"
        className={twx(
          'relative z-[3] h-screen w-screen min-w-[248px] bg-border text-[12px] font-medium leading-4 text-white transition-all xs:h-auto xs:w-[248px] print:hidden',
          {
            'h-[72px]': !showMobileSidebar,
          },
          className
        )}
      >
        <div
          className={tw`xs:min-h-auto sticky top-0 h-full min-h-full overflow-hidden xs:h-auto xs:min-h-screen xs:overflow-visible`}
        >
          <div
            className={tw`absolute right-[24px] top-[24px] z-[3] block cursor-pointer text-[24px] xs:hidden`}
            onClick={(ev) => {
              ev.stopPropagation()
              setShowMobileSidebar(!showMobileSidebar)
            }}
          >
            <i className={tw`fas fa-bars`} />
          </div>
          {children}
        </div>
      </nav>
    </SidebarContext.Provider>
  )
}
