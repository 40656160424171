import { ReactNode, useState } from 'react'
import {
  Controller,
  FieldPath,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReturn,
  UseFormWatch,
} from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import StyledSelect from '@shared/components/StyledSelect'
import { AlertTooltip } from '@shared/components/Tooltips/AlertTooltip'
import { hasWeightSignificantlyChanged } from '@shared/components/Vitals/helpers'
import { LatestVital } from '@shared/types/latest_vital'
import { Loading } from '@shared/utils/loading'
import { twx } from '@shared/utils/tailwind'
import {
  VITALS_INPUT_PROPS_MAP,
  VitalsFormData,
  VitalsType,
} from '@shared/utils/vitals'

type VitalsSaveMode =
  | {
      tag: 'onBlur'
      handleSubmit: UseFormHandleSubmit<VitalsFormData>
      onSubmit: (formData: VitalsFormData) => Promise<void>
    }
  | {
      tag: 'onClickSave'
    }

interface Props {
  methods: UseFormReturn<VitalsFormData>
  mode: VitalsSaveMode
  latestVitals: Loading<LatestVital>
  vitalsType: VitalsType
  showTooltips?: boolean
  className?: string
}

export default function VitalsInputProducer({
  methods,
  mode,
  latestVitals,
  vitalsType,
  showTooltips = true,
  className = '',
}: Props) {
  const {
    control,
    register,
    watch,
    setFocus,
    getValues,
    formState,
    getFieldState,
  } = methods
  const {
    iconClassName,
    name,
    label,
    unitsLabel,
    dropdownName,
    placeholder,
    options,
  } = VITALS_INPUT_PROPS_MAP[vitalsType]

  const submitOnBlur = mode.tag === 'onBlur'
  const inputHasValue = Boolean(watch(name))
  const inputClasses = getInputClasses(inputHasValue)

  const isWeightInput = vitalsType === VitalsType.WEIGHT
  const isBloodPressureInput = vitalsType === VitalsType.BLOOD_PRESSURE

  const onBlur = () => {
    if (submitOnBlur) {
      void mode.handleSubmit(mode.onSubmit)()
    }
  }

  const { error, isDirty } = getFieldState(name, formState)
  const checkDiastolic = () => {
    if (isBloodPressureInput) {
      return !!getFieldState('diastolic', formState).error
    }
    return false
  }

  const validate = (value: string) => {
    if (isDirty && Number(value) <= 0) {
      return `${name} has value less than or equal to 0`
    }
    return true
  }

  const vitalsInputContainerClasses = twx(
    'flex items-center justify-between grow h-largeInput border border-form-input-border rounded-input p-inputPadding bg-white mb-[8px]',
    'hover:border-form-input-border-hover focus-within:border-form-input-border-hover focus-within:outline-double focus-within:outline-1 focus-within:outline-form-input-border-hover',
    {
      ['border-form-input-error-border hover:border-form-input-error-border focus-within:border-form-input-error-border focus-within:outline-form-input-error-border']:
        !!error || checkDiastolic(),
    }
  )

  const propsForInput = {
    register,
    iconClassName,
    onBlur,
    inputClasses,
    name,
    label,
    showTooltips,
    validate,
  }

  const VitalsInputComponent = () => {
    if (isWeightInput) {
      return (
        <WeightVitalsInput
          {...propsForInput}
          getValues={getValues}
          latestVitals={latestVitals}
        />
      )
    } else if (isBloodPressureInput) {
      return <BloodPressureVitalsInput {...propsForInput} watch={watch} />
    } else {
      return <VitalsInput {...propsForInput} />
    }
  }

  return (
    <div className={twx('flex justify-between', className)}>
      <VitalsInputContainer
        onClick={() => {
          if (!isBloodPressureInput) {
            setFocus(name)
          }
        }}
        unitsLabel={unitsLabel}
        className={twx(vitalsInputContainerClasses)}
        testId={`vitalsInputContainer-${name}`}
      >
        {VitalsInputComponent()}
      </VitalsInputContainer>
      {[
        VitalsType.BLOOD_SUGAR,
        VitalsType.OXYGEN_SATURATION,
        VitalsType.TEMPERATURE,
        VitalsType.BLOOD_PRESSURE,
      ].includes(vitalsType) && (
        <Controller
          control={control}
          name={dropdownName as FieldPath<VitalsFormData>}
          render={({ field: { onChange, value } }) => (
            <>
              <label className={twx('visually-hidden')} htmlFor={dropdownName}>
                {dropdownName}
              </label>
              <StyledSelect
                name={dropdownName}
                size={'large'}
                inputId={dropdownName}
                value={value}
                onChange={onChange}
                blurInputOnSelect
                onBlur={onBlur}
                options={options}
                className={twx('ml-2 w-[184px] text-[4px]')}
                placeholder={placeholder}
                menuPlacement={
                  [
                    VitalsType.BLOOD_SUGAR,
                    VitalsType.OXYGEN_SATURATION,
                  ].includes(vitalsType)
                    ? 'top'
                    : 'auto'
                }
              />
            </>
          )}
        />
      )}
    </div>
  )
}

type VitalsInputProps = {
  register: UseFormRegister<VitalsFormData>
  onBlur: () => void
  isRequired?: boolean
  inputClasses: string
  name: keyof VitalsFormData
  children?: ReactNode
  iconClassName: string
  label: string
  alertTooltip?: ReactNode
  inputWrapperClasses?: string
  showTooltips?: boolean
  validate: (value: string) => string | boolean
}

const VitalsInput = (props: VitalsInputProps) => {
  const {
    register,
    onBlur,
    isRequired,
    inputClasses,
    name,
    label,
    iconClassName,
    alertTooltip,
    inputWrapperClasses,
    children,
    showTooltips,
    validate,
  } = props

  const iconClasses = twx(iconClassName, 'fa-fw text-primary-light')
  const inputWrapperClassName = twx('flex items-center', inputWrapperClasses)

  return (
    <div className={twx('flex items-center')}>
      <i className={twx(iconClasses)} />
      <VitalsLabel name={name} label={label} />
      <div className={twx(inputWrapperClassName)}>
        {showTooltips && alertTooltip}
        <BasicInput
          type="number"
          className={twx(inputClasses)}
          id={name}
          min={0}
          step="any"
          inputSize={'inline'}
          {...register(name, {
            required: isRequired,
            onBlur,
            validate,
          })}
        />
        {children}
      </div>
    </div>
  )
}

const VitalsLabel = (props: { name: keyof VitalsFormData; label: string }) => {
  const { name, label } = props
  return (
    <label
      htmlFor={name}
      className={twx(
        'mx-[8px] my-0 whitespace-nowrap text-[14px] font-medium leading-[28px]'
      )}
    >
      {label}:
    </label>
  )
}

const VitalsInputContainer = (props: {
  children: ReactNode
  onClick: () => void
  unitsLabel: string
  className: string
  testId: string
}) => {
  const { children, onClick, unitsLabel, className, testId } = props
  return (
    <div className={twx(className)} onClick={onClick} data-testid={testId}>
      {children}
      <span
        className={twx(
          'ml-[8px] text-[14px] font-medium leading-[16px] text-gray-08'
        )}
      >
        {unitsLabel}
      </span>
    </div>
  )
}

const BloodPressureVitalsInput = (
  props: VitalsInputProps & { watch: UseFormWatch<VitalsFormData> }
) => {
  const { register, onBlur, validate, watch, ...rest } = props

  const diastolicHasValue = Boolean(watch('diastolic'))
  const diastolicInputClasses = getInputClasses(diastolicHasValue)
  const required = diastolicHasValue

  return (
    <VitalsInput
      onBlur={onBlur}
      isRequired={required}
      register={register}
      validate={validate}
      {...rest}
    >
      <span className={twx(`mx-[4px] my-0 text-gray-08`)}>/</span>
      <BasicInput
        className={twx(diastolicInputClasses)}
        id="diastolic"
        type="number"
        min={0}
        step="any"
        inputSize={'inline'}
        {...register('diastolic', {
          required,
          validate,
          onBlur,
        })}
      />
    </VitalsInput>
  )
}

const WeightVitalsInput = (
  props: VitalsInputProps & {
    latestVitals: Loading<LatestVital>
    getValues: UseFormGetValues<VitalsFormData>
  }
) => {
  const { onBlur, latestVitals, getValues, ...rest } = props

  const [weightAlert, setWeightAlert] = useState<
    'Never shown' | 'Showing' | 'Dismissed'
  >('Never shown')

  const checkWeightDifference = () => {
    if (weightAlert === 'Dismissed') {
      return
    }

    const data = getValues()
    if (
      latestVitals.tag === 'Complete' &&
      data.weight &&
      hasWeightSignificantlyChanged(data, latestVitals.value)
    ) {
      setWeightAlert('Showing')
    }
  }
  const inputOnBlur = () => {
    checkWeightDifference()
    onBlur()
  }

  const alertToolTip = weightAlert === 'Showing' && (
    <AlertTooltip
      className={twx('absolute left-[-6px] top-[calc(100%+12px)]')}
      onClose={() => {
        setWeightAlert('Dismissed')
      }}
      caretPosition={'top'}
    />
  )

  return (
    <VitalsInput
      onBlur={inputOnBlur}
      inputWrapperClasses={twx('relative')}
      alertTooltip={alertToolTip}
      {...rest}
    />
  )
}
const getInputClasses = (hasValue: boolean) => {
  return twx(
    'bg-transparent border-0 border-b border-solid border-gray-08 text-formInput w-[64px] rounded-b-none h-vitalInput px-[8px] py-[14px]',
    'hover:outline-0 focus:outline-0 focus-visible:outline-0 active:outline-0',
    {
      ['border-b-transparent text-gray-03 text-formInput']: hasValue,
    }
  )
}
