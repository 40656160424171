import { CSSProperties, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import Card from '@shared/components/Card'
import { twx } from '@shared/utils/tailwind'

interface LinkCardProps extends PropsWithChildren {
  id?: string
  style?: CSSProperties
  className?: string
  anchorClassName?: string
  href: string
}

export function LinkCard({
  href,
  children,
  className,
  anchorClassName,
  ...rest
}: LinkCardProps) {
  // TODO: Remove this component in favor of `Card` with the `href` prop
  return (
    <Link
      to={href}
      className={twx('block no-underline', anchorClassName)}
      {...rest}
    >
      <Card className={twx(className)}>{children}</Card>
    </Link>
  )
}
