import { Auth } from 'aws-amplify'

function wait(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

async function retry<T>(
  f: () => Promise<T>,
  retries: number = 3,
  delay: number = 1500
): Promise<T> {
  let attempts = 0

  while (attempts < retries) {
    try {
      return await f()
    } catch (e) {
      attempts += 1
      if (attempts >= retries) {
        throw e
      }

      const backoff = delay * Math.pow(2, attempts - 1)
      await wait(backoff)
    }
  }

  throw new Error('Unreachable!')
}

export default async function fetchAuthSessionWithRetries() {
  return await retry(() => Auth.currentSession())
}
