import { extraErrorDataIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { browserTracingIntegration, replayIntegration } from '@sentry/react'
import { get } from 'lodash'
import environment, { SENTRY_DSN } from '@shared/environment'
import { Page } from '@shared/hooks/useCurrentPage'
import { UserAccount } from '@shared/types/user'
import { errorConsoleMessage } from '@shared/utils/console'
import { generateCloudwatchUrl } from '@shared/utils/error'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class ErrorMonitoring {
  static reportExternally = environment.sentryEnabled

  public static init() {
    if (this.reportExternally) {
      initSentry()
    }
  }

  public static addRequestBreadcrumb({
    url,
    requestId,
  }: {
    url: string
    requestId: string
  }) {
    if (this.reportExternally) {
      Sentry.addBreadcrumb({
        category: 'fetch',
        message: 'Initiating request ' + url,
        level: 'info',
        data: {
          requestId,
          cloudwatchUrl: generateCloudwatchUrl(requestId),
        },
      })
    }
  }

  public static capture({
    error,
    level = 'error',
    tags,
    extras,
  }: {
    error: unknown
    level?: ErrorLevel
    tags?: Record<string, string>
    extras?: Record<string, string>
  }) {
    if (!this.reportExternally && environment.name !== 'test') {
      // @ts-expect-error - Log to console in development
      const loggedError = errorConsoleMessage(`${level} sentry capture`, error)
      if (tags || extras) {
        console.groupCollapsed(...loggedError)
        tags && console.log('Error tags', tags)
        extras && console.log('Error extras', extras)
        console.groupEnd()
      } else {
        console.log(...loggedError)
      }
    } else {
      if (typeof error === 'string') {
        Sentry.withScope((scope) => {
          if (tags) {
            scope.setTags(tags)
          }

          if (extras) {
            scope.setExtras(extras)
          }

          scope.captureMessage(error, level)
        })
      } else {
        tags && Sentry.setTags(tags)
        extras && Sentry.setExtras(extras)
        Sentry.captureException(error, { level })
      }
    }
  }

  public static setUser(user: UserAccount) {
    if (this.reportExternally) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
        username: user.preferredUsername,
      })
    }
  }

  public static setPage(page: Page) {
    if (this.reportExternally) {
      Sentry.setContext('page', page)
    }
  }

  public static Boundary = Sentry.ErrorBoundary
}

export type ErrorLevel = 'error' | 'warning' | 'log' | 'debug'

const initSentry = () => {
  if (environment.sentryEnabled) {
    Sentry.init({
      dsn: SENTRY_DSN,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: environment.name,
      release: environment.clientVersion,
      tracesSampleRate: 1.0,
      transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
      transportOptions: {
        maxQueueSize: 10000,
      },
      integrations: [
        browserTracingIntegration(),
        extraErrorDataIntegration({ depth: 10 }),
        replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      beforeSend: (event) => {
        try {
          const exception = get(event, 'exception.values.0')
          if (exception) {
            const exceptionType = exception.type
            const exceptionValue = exception.value
            const exceptionSummary = `${exceptionType}: ${exceptionValue}`
            exception.type = exceptionSummary
            exception.value = exceptionSummary
            event.message = exceptionSummary
            event.fingerprint = [exceptionSummary]
          }

          return event
        } catch (error) {
          return event
        }
      },
      ignoreErrors: [
        // Broken Outlook Safe Links scanning:
        // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-1233146122
        'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
      ],
    })
  }
}
