// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import clsx, { ClassValue } from 'clsx'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { twMerge } from 'tailwind-merge'

const identity = (
  strings: TemplateStringsArray,
  ...values: Array<unknown>
): string => String.raw({ raw: strings }, ...values)

export const tw = (
  strings: TemplateStringsArray,
  ...values: Array<unknown>
): string => twMerge(clsx(identity(strings, ...values)))

export const twx = (...classLists: ClassValue[]): string =>
  twMerge(clsx(...classLists))
