import { twx } from '@shared/utils/tailwind'

type IconVariant = 'solid' | 'regular' | 'light' | 'duotone' | 'thin'

type Props = React.HTMLProps<HTMLElement> & {
  className?: string
  name: string
  variant?: IconVariant
}

export default function Icon({
  className,
  name,
  variant = 'solid',
  ...rest
}: Props) {
  return (
    <i
      className={twx(className, `fa-fw fa-${name.replace('fa-', '')}`, {
        'fa-solid': variant === 'solid',
        'fa-regular': variant === 'regular',
        'fa-light': variant === 'light',
        'fa-duotone': variant === 'duotone',
        'fa-thin': variant === 'thin',
      })}
      aria-hidden="true"
      {...rest}
    />
  )
}
