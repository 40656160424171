import { GroupBase, StylesConfig } from 'react-select'
import {
  baseSelectControlStyles,
  errorBorderControlStyles,
  selectStyles,
} from '@shared/components/StyledSelect'

export const ErrorDropdownStyleConfig = ({
  error,
  width,
}: {
  error: boolean
  width?: string
}): StylesConfig<unknown, boolean, GroupBase<unknown>> => {
  const widthStyle = width ?? 'fit-content'
  return {
    ...selectStyles(),
    control: (provided, state) => ({
      ...provided,
      ...baseSelectControlStyles('medium', state),
      ...errorBorderControlStyles(error, state),
      minWidth: '96px',
      width: widthStyle,
    }),
    menu: (provided) => ({
      ...provided,
      width: widthStyle,
    }),
  }
}
