import DatePicker from 'react-datepicker'
import { Controller, useFormContext } from 'react-hook-form'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import {
  BasicInput,
  BasicTextarea,
} from '@shared/components/BasicInput/BasicInput'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { EmbossedCard } from '@shared/components/EmbossedCard'
import { RequiredLabel } from '@shared/components/Labels'
import { doesFormHaveErrors } from '@shared/components/Vitals/helpers'
import VitalsInputProducer from '@shared/components/Vitals/VitalsInputProducer'
import { LatestVital } from '@shared/types/latest_vital'
import { loaded, Loading } from '@shared/utils/loading'
import { twx } from '@shared/utils/tailwind'
import { VitalsFormData, VitalsType } from '@shared/utils/vitals'

type Props = {
  onSubmit: (formVital: VitalsFormData) => Promise<void>
  onCancel: () => void
  latestVitals?: Loading<LatestVital>
  hideAdditionalNote?: boolean
}

export const VitalsModalContents = ({
  onSubmit,
  latestVitals = loaded({}),
  onCancel,
  hideAdditionalNote = false,
}: Props) => {
  const methods = useFormContext<VitalsFormData>()
  const { getValues, handleSubmit, formState, control, register } = methods

  const hasAnyVitalsValues = () => {
    return getValues([
      'weight',
      'temperature',
      'heartRate',
      'systolic',
      'diastolic',
      'respiratoryRate',
      'oxygenSaturation',
      'bloodSugar',
    ]).some((v) => v)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="vitals-form">
      <div className={twx('mb-[24px]')}>
        <h1
          className={twx(
            'mb-[24px] text-[24px] font-medium leading-[36px] text-secondary-02'
          )}
        >
          Add Vitals{' '}
          <RequiredLabel
            showError={doesFormHaveErrors(formState)}
            hideDefaultRequired={true}
            text={'Value must be greater or equal to zero'}
          />
        </h1>
        <EmbossedCard
          className={twx(
            'mb-4 flex grow flex-row justify-between gap-2 p-4 [&>*]:flex-1 '
          )}
        >
          <Controller
            control={control}
            name="date"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                id="date"
                selected={value}
                onChange={(v: Date | null, context) => {
                  onChange(v, context)
                }}
                customInput={<CalendarInput width={'100%'} />}
                maxDate={new Date()}
                enableTabLoop={false}
              />
            )}
          />
          <BasicInput type="time" id="time" {...register('time')} />
        </EmbossedCard>
        {Object.keys(VitalsType).map((type) => (
          <VitalsInputProducer
            key={type}
            latestVitals={latestVitals}
            methods={methods}
            mode={{ tag: 'onClickSave' }}
            vitalsType={VitalsType[type] as VitalsType}
          />
        ))}
        {!hideAdditionalNote && (
          <div className={twx('mt-[8px]')}>
            <BasicTextarea
              {...register('note')}
              placeholder="Optional note..."
            />
          </div>
        )}
      </div>

      <AnimatedPopupFormFooter
        formState={formState}
        noBtn={{ action: () => onCancel() }}
        yesBtn={{
          props: {
            id: 'save-vitals',
            ['data-testid']: 'save-vitals',
            disabled: formState.isSubmitting || !hasAnyVitalsValues(),
          },
        }}
      />
    </form>
  )
}
