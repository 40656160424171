import { twx } from '@shared/utils/tailwind'

export type VerticalBarColors = 'primary-light' | 'tags-red' | 'pill-warning'

const colorToClassName: Record<string, string> = {
  'primary-light': 'after:bg-primary-light',
  'tags-red': 'after:bg-tags-red',
  'pill-warning': 'after:bg-pill-warning',
}
export const VerticalRoundedBar = ({
  color = 'primary-light',
  disabled = false,
}: {
  color?: VerticalBarColors
  disabled?: boolean
}) => {
  const calculatedColor: string = colorToClassName[color]

  return (
    <div
      aria-hidden={true}
      className={twx(
        "after:content-[' '] after:mr-4 after:block after:h-full after:w-[2px] after:rounded-[1px]",
        {
          [calculatedColor]: calculatedColor && !disabled,
          [`after:bg-secondary-07`]: disabled,
        }
      )}
    />
  )
}
