import React, { CSSProperties, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { twx } from '@shared/utils/tailwind'

type CardStyle = 'default' | 'focal'

interface BaseProps extends PropsWithChildren {
  id?: string
  style?: CSSProperties
  className?: string
  cardStyle?: CardStyle
  role?: string
  'data-testid'?: string
}

interface ButtonProps extends BaseProps {
  onClick?: ((e?: React.MouseEvent) => void) | undefined
  href?: undefined
}

interface LinkProps {
  onClick?: undefined
  href?: string | undefined
}

type Props = (BaseProps & ButtonProps) | (BaseProps & LinkProps)

export default function Card({
  className,
  children,
  cardStyle = 'default',
  href,
  onClick,
  role,
  ...rest
}: Props) {
  const dataTestId = rest['data-testid'] || rest.id
  const isButtonCard = onClick !== undefined
  const isLinkCard = href !== undefined

  const classNames = twx(
    'flex rounded-[8px] p-[24px]',
    {
      'bg-white shadow-card': cardStyle === 'default',
      'border border-secondary-10 bg-gray-12 shadow-card hover:card-outline':
        cardStyle === 'focal',
      'cursor-pointer no-underline': isLinkCard,
      'cursor-pointer text-left': isButtonCard,
    },
    className
  )

  if (onClick) {
    return (
      <div
        role="button"
        tabIndex={0}
        onKeyUp={({ key }: React.KeyboardEvent<HTMLDivElement>) => {
          if (key === 'Enter' || key === ' ') {
            onClick()
          }
        }}
        onClick={(e) => {
          if (e.target instanceof Element) {
            const closestButton = e.target.closest('button')
            const closestAnchor = e.target.closest('a')
            if (closestButton !== null || closestAnchor !== null) {
              return
            }
          }

          onClick()
        }}
        className={twx(classNames)}
        data-testid={dataTestId}
        {...rest}
      >
        {children}
      </div>
    )
  }

  if (href) {
    return (
      <Link
        data-testid={dataTestId}
        role={role}
        to={href}
        className={twx(classNames)}
        {...rest}
      >
        {children}
      </Link>
    )
  }

  return (
    <>
      <div
        data-testid={dataTestId}
        role={role}
        className={twx(classNames)}
        {...rest}
      >
        {children}
      </div>
    </>
  )
}
