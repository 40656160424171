import { tw } from '@shared/utils/tailwind'

const css = `
/* Inline style tag here until we have a layout for the main app */
[aria-label="Main Navigation"] {
  display: none;
}
`

export default function NotFound() {
  return (
    <>
      <style type="text/css">{css}</style>
      <div className={tw`mt-[250px] flex flex-col items-center justify-center`}>
        <img src="/images/sad-people.svg" alt="Group of sad cartoon people" />
        <h1 className={tw`pt-2 text-[25px] font-normal`}>
          Unfortunately, we were not able to find this page.
        </h1>
        <p className={tw`flex flex-row gap-1 pt-2 text-[18px]`}>
          Click
          <a href="/" className={tw`text-link hover:underline`}>
            here
          </a>
          to head back.
        </p>
      </div>
    </>
  )
}
